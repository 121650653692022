<template>
  <div class="img-wrap">
    <div class="img-content" v-for="(item, index) in imgList" :key="index">
      <el-image
        style="width: 100px; height: 100px; margin: 20px"
        :src="item"
        :preview-src-list="imgList"
      >
      </el-image>
      <span>判断结果：{{isStandard(item)? '异常': '合格'}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    checkPicList: Array,
  },
  watch: {
    value() {
      // const temp = [];
      let photos = '';
      console.log(this.value);
      photos = this.value[0].photos;
      this.imgList = photos.split(',');
      // for (const item of this.value) {
      //   if (item.photos.indexOf(',') !== -1) {
      //     const photos = item.photos.split(',');
      //     temp.push(photos);
      //   } else {
      //     temp.push(item.photos);
      //   }
      // }
      // console.log(temp);
      // this.imgList = temp;
      // console.log(this.imgList);
    },
    checkPicList(val) {
      console.log(val);
      this.checkImageList = val;
    },
  },
  computed: {
    isStandard() {
      return (pic) => this.checkImageList.some((p) => p.fileUrl === pic);
    },
  },
  data() {
    return {
      imgList: [],
      checkImageList: [],
    };
  },

  methods: {},
};
</script>
<style scoped>
.img-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.img-content{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
</style>
